.meet-our-team {
    margin: 20px 0;
    color: #002C3D;
}

.meet-our-team h2 {
    font-size: 2em;
    margin-bottom: 30px;
    text-align: center;
    color: #005577;
    font-weight: 500;
}

.team-members-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
    justify-items: center;
}

.team-member {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.team-member img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    transition: transform 0.3s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.team-member:hover img {
    transform: scale(1.1);
}

.description-box {
    display: none;
    position: absolute;
    bottom: -120px;
    width: 250px;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    z-index: 10;
    color: #002C3D;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.description-box h3 {
    margin: 10px 0;
    font-size: 1.2em;
    color: #005577;
}

.description-box p.role {
    margin-bottom: 10px;
    font-style: italic;
    color: #005577;
}

.team-member:hover .description-box {
    display: block;
    opacity: 1;
}

/* Responsive styles */
@media (max-width: 768px) {
    .team-member img {
        width: 120px;
        height: 120px;
    }

    .description-box {
        width: 220px;
        bottom: -110px;
    }
}

@media (max-width: 480px) {
    .team-member img {
        width: 100px;
        height: 100px;
    }

    .description-box {
        width: 180px;
        bottom: -100px;
    }

    .description-box h3 {
        font-size: 1em;
    }

    .description-box p {
        font-size: 0.9em;
    }
}
