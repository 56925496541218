.join-us-page {
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
    color: #002C3D;
    position: relative;
    z-index: 1;
}

.join-us-page::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../assets/Background/SeattleImageFreeToUse.jpg') center center/cover no-repeat;
    opacity: 0.5;
    z-index: -1;
}

.join-intro {
    text-align: center;
    margin-bottom: 40px;
}

.join-intro h1 {
    font-size: 2.5em;
    color: #005577;
    font-weight: 600;
    margin-bottom: 20px;
}

.join-intro p {
    font-size: 1.2em;
    margin-bottom: 40px;
    color: #002C3D;
}

.join-options {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 50px;
}

.join-options div {
    width: 45%;
    text-align: center;
    padding: 20px;
    background-color: rgba(249, 252, 255, 0.9);
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.join-options h2 {
    font-size: 2em;
    color: #005577;
    margin-bottom: 20px;
    font-weight: 500;
}

.join-options p {
    font-size: 1.2em;
    margin-bottom: 30px;
}

.cta-button {
    display: inline-block;
    padding: 12px 25px;
    background-color: #3CAFDC;
    color: white;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.cta-button:hover {
    background-color: #77E991;
    transform: scale(1.05);
}

.how-it-works {
    text-align: center;
    margin-top: 60px;
    padding: 20px;
    background-color: rgba(249, 252, 255, 0.9);
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.how-it-works h2 {
    font-size: 2.5em;
    color: #005577;
    margin-bottom: 30px;
    font-weight: 500;
}

.steps {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.steps .step {
    width: 22%;
    padding: 20px;
    background-color: rgba(188, 236, 255, 0.9);
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
}

.steps .step:hover {
    transform: translateY(-10px);
}

.steps h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #005577;
}

.steps p {
    font-size: 1.1em;
    color: #005577;
}

@media (max-width: 768px) {
    .join-options {
        flex-direction: column;
    }

    .join-options div {
        width: 100%;
        margin-bottom: 20px;
    }

    .steps {
        flex-direction: column;
        gap: 20px;
    }

    .steps .step {
        width: 100%;
    }

    .join-intro h1,
    .how-it-works h2 {
        font-size: 2em;
    }

    .join-intro p {
        font-size: 1.3em;
    }
}

@media (max-width: 480px) {
    .join-intro h1,
    .how-it-works h2 {
        font-size: 1.8em;
    }

    .cta-button {
        width: 100%;
        padding: 15px;
    }

    .join-options p,
    .steps p {
        font-size: 1em;
    }

    .steps .step {
        padding: 15px;
    }
}
