.platform {
    position: relative;
    text-align: center;
    padding: 5vh 10vw;
    background-color: #EBF9FF;
}

.platform-box {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 20vw);
    height: auto;
    min-height: 15vh;
    background-color: rgba(103, 124, 132, 0.13);
    z-index: 0;
    border-radius: 10px;
}

.platform h2 {
    position: relative;
    font-size: 3vh;
    margin-bottom: 4vh;
    color: #002C3D;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    z-index: 2;
}

.platform-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5vw;
    margin-top: 8vh;
    z-index: 1;
    position: relative;
    flex-wrap: wrap;
}

.platform-logo {
    width: auto;
    height: auto;
}

.platform-logo.clover {
    max-height: 6vh;
}

.platform-logo.square {
    max-height: 8vh;
}

@media (max-width: 768px) {
    .platform {
        padding: 5vh 5vw;
    }

    .platform h2 {
        font-size: 2.5vh;
    }

    .platform-logos {
        flex-direction: column;
        gap: 3vh;
    }

    .platform-box {
        width: calc(100% - 10vw);
        top: 65%;
        min-height: 18vh;
    }
}

@media (max-width: 480px) {
    .platform {
        padding: 5vh 5vw;
    }

    .platform h2 {
        font-size: 2vh;
    }

    .platform-logos {
        gap: 2vh;
    }

    .platform-box {
        width: calc(100% - 5vw);
        top: 64%;
        min-height: 20vh;
    }

    .platform-logo {
        max-height: 5vh;
    }
}
