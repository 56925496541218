.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3vh 10vw;
    background-color: #EBF9FF;
    color: white;
    font-size: 1.2rem;
    width: 100%;
    max-width: 100vw;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
}

.logo img {
    height: 10vh;
    width: auto;
}

.navigation {
    display: flex;
    align-items: center;
}

.navigation a {
    margin: 0 1vw;
    color: black;
    text-decoration: none;
    font-weight: 450;
}

.navigation a:hover {
    text-decoration: underline;
}

.hamburger {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 25px;
    cursor: pointer;
}

.bar {
    height: 3px;
    width: 100%;
    background-color: black;
    transition: all 0.3s ease;
}

.bar.open:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.bar.open:nth-child(2) {
    opacity: 0;
}

.bar.open:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
}

@media (max-width: 768px) {
    .header {
        padding: 3vh 5vw;
        font-size: 1rem;
    }

    .logo img {
        height: 8vh;
    }

    .navigation {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        flex-direction: column;
        align-items: center;
        background-color: rgba(220, 249, 255, 0.85);
        padding: 1vh 0;
        display: none;
        transition: opacity 0.3s ease, visibility 0.3s ease;
    }

    .navigation.open {
        display: flex;
        opacity: 1;
    }

    .navigation a {
        margin: 1vh 0;
        font-size: 1.2rem;
    }

    .hamburger {
        display: flex;
    }
}

@media (max-width: 480px) {
    .header {
        padding: 2vh 3vw;
        font-size: 0.9rem;
    }

    .logo img {
        height: 6vh;
    }

    .navigation a {
        font-size: 1rem;
    }
}
