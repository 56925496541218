.hero {
    padding: 5vh 13vw;
    background-color: #EBF9FF;
}

.hero-content {
    display: flex;
    flex-wrap: wrap;
    gap: 2vw;
    max-width: 100%;
}

.hero-left {
    text-align: left;
    flex: 1;
}

.hero-left h1 {
    font-size: 4vh;
    margin-bottom: 2vh;
}

.hero-left p {
    font-size: 2vh;
    margin-bottom: 2vh;
}

.hero-buttons {
    display: flex;
    gap: 2vw;
    flex-wrap: wrap;
}

.hero-buttons .btn-primary, .hero-buttons .btn-secondary {
    padding: 1.5vh 3vw;
    font-size: 2vh;
    border: none;
    border-radius: 30px;
    font-weight: bold;
}

.hero-buttons .btn-primary {
    background-color: #61dafb;
    color: #002C3D;
}

.hero-buttons .btn-secondary {
    background-color: #282c34;
    color: white;
}

.hero-right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.hero-video {
    width: 100%;
    max-width: 100%;
    aspect-ratio: 16/9;
    border: none;
}

@media (min-width: 768px) {
    .hero-content {
        flex-direction: row;
    }
}

@media (max-width: 767px) {
    .hero-content {
        flex-direction: column;
    }

    .hero-left h1 {
        font-size: 3.5vh;
    }

    .hero-left p {
        font-size: 1.8vh;
    }

    .hero-buttons .btn-primary, .hero-buttons .btn-secondary {
        font-size: 1.8vh;
    }
}

@media (max-width: 480px) {
    .hero {
        padding: 3vh 5vw;
    }

    .hero-left h1 {
        font-size: 3vh;
    }

    .hero-left p {
        font-size: 1.6vh;
    }

    .hero-buttons {
        flex-direction: column;
    }

    .hero-buttons .btn-primary, .hero-buttons .btn-secondary {
        width: 100%;
        padding: 1.2vh 2vw;
        font-size: 1.6vh;
    }
}
