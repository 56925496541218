.founding-partners {
    position: relative;
    text-align: center;
    padding: 5vh 10vw;
    background-color: #EBF9FF;
}

.founding-partners h2 {
    font-size: 3vh;
    margin-bottom: 4vh;
    color: #002C3D;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    z-index: 2;
}

.founding-partners-logos-container {
    position: relative;
    margin-top: 4vh;
}

.founding-partners-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 15vh;
    background-color: rgba(103, 124, 132, 0.13);
    z-index: 0;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.founding-partners-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5vw;
    z-index: 1;
    flex-wrap: wrap;
    position: relative;
    max-height: none;
}

.founding-partners-logo {
    width: auto;
    position: relative;
    z-index: 2;
    max-height: 10vh;
}

.founding-partners-logo.madeinwashington {
    height: 12vh;
}

.founding-partners-logo.birthdaydreams {
    height: 10vh;
}

.founding-partners-logo.pugetsoundkeeper {
    height: auto;
    max-height: 13vh;
}

.founding-partners-logo.sr3 {
    height: 8vh;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .founding-partners {
        padding: 5vh 5vw;
    }

    .founding-partners h2 {
        font-size: 2.5vh;
    }

    .founding-partners-logos {
        flex-direction: column;
        gap: 3vh;
        position: relative;
    }

    .founding-partners-box {
        top: 62%;
        width: 80%;
        height: auto;
        min-height: 40vh;
        transform: translate(-50%, -62%);
    }

    .founding-partners-logo {
        max-height: 8vh;
    }

    .founding-partners-logo.pugetsoundkeeper {
        height: 10vh;
    }
}

@media (max-width: 480px) {
    .founding-partners h2 {
        font-size: 2vh;
    }

    .founding-partners-logos {
        gap: 2vh;
    }

    .founding-partners-box {
        top: 72%;
        width: 100%;
        height: auto;
        min-height: 35vh;
        transform: translate(-50%, -68%);
    }

    .founding-partners-logo {
        max-height: 6vh;
    }

    .founding-partners-logo.pugetsoundkeeper {
        height: 8vh;
    }
}

