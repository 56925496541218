.how-it-works {
    padding: 5vh 0;
}

.how-it-works-content {
    background-color: rgba(143, 197, 255, 0.5);
    width: calc(100% - 26vw);
    max-width: 1440px;
    margin: 0 auto;
    padding: 5vh 2vw;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    box-sizing: border-box;
}

.how-it-works-info-container {
    text-align: center;
    margin-bottom: 5vh;
}

.how-it-works-info-content h2 {
    font-size: 4vh;
    color: #002C3D;
    margin-bottom: 2vh;
}

.how-it-works-info-content p {
    font-size: 2.5vh;
    color: #333;
    margin-bottom: 1vh;
    line-height: 1.5;
}

.businesses-charities-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.section {
    background-color: #EBF4FF;
    padding: 3vh 2vw;
    border-radius: 10px;
    flex: 1;
    margin: 0 1vw;
    min-width: 250px;
    box-sizing: border-box;
}

.section-title {
    display: flex;
    align-items: center;
    margin-bottom: 2vh;
}

h2 {
    font-size: 2.5vh;
    color: #002C3D;
    font-weight: bold;
    margin: 0;
}

ul {
    list-style: none;
    margin-top: 2vh;
    padding: 0 0 0 2vw;
    box-sizing: border-box;
}

ul li {
    display: flex;
    align-items: center;
    margin-bottom: 1.5vh;
    font-size: 2vh;
    color: #333;
    min-width: 250px;
}

ul li img {
    margin-right: 1vw;
    height: 3vh;
    width: 3vh;
}

@media (max-width: 768px) {
    .how-it-works-content {
        width: 90vw;
        padding: 5vh 4vw;
    }

    .businesses-charities-container {
        flex-direction: column;
        align-items: center;
    }

    .section {
        flex: 1 1 100%;
        margin: 2vh 0;
        min-width: 90%;
    }

    ul li {
        min-width: 100%;
    }
}

@media (max-width: 480px) {
    .how-it-works-content {
        width: 95vw;
        padding: 4vh 2vw;
    }

    .how-it-works-info-content h2 {
        font-size: 3vh;
    }

    .how-it-works-info-content p {
        font-size: 1.8vh;
    }

    ul li {
        font-size: 1.8vh;
        min-width: 100%;
    }
}
