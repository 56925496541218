.contact-page {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
}

.contact-page h1 {
    margin-bottom: 20px;
    color: #005577;
}

/* Styling for the form */
.contact-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
    text-align: left;
}

.form-group label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.form-group textarea {
    resize: vertical;
    height: 100px;
}

.submit-button {
    padding: 10px 20px;
    font-size: 18px;
    color: #fff;
    background-color: #005577;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #003f5c;
}
