.signup-page {
    padding: 40px 20px;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    background-color: #f9fcff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.signup-intro {
    margin-bottom: 30px;
}

.signup-intro h1 {
    font-size: 2.2em;
    color: #005577;
    margin-bottom: 10px;
}

.signup-intro p {
    font-size: 1.2em;
    color: #002C3D;
}

.signup-form {
    padding: 20px;
}

.form-group {
    margin-bottom: 20px;
    text-align: left;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #002C3D;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="password"] {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1em;
}

.cta-button {
    width: 100%;
    padding: 12px 20px;
    background-color: #3CAFDC;
    color: white;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    cursor: pointer;
    border: none;
}

.cta-button:hover {
    background-color: #77E991;
}

.login-redirect {
    margin-top: 20px;
    font-size: 1em;
}

.login-redirect a {
    color: #005577;
    text-decoration: none;
}

.login-redirect a:hover {
    text-decoration: underline;
}
