.about-page {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    color: #002C3D;
}

.about-page h1 {
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 40px;
    color: #005577;
}

/* Responsive styles */
@media (max-width: 768px) {
    .about-page h1 {
        font-size: 2em;
    }
}
