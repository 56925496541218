.mission-statement {
    margin: 20px 0;
    padding: 20px;
    background-color: #BCECFF;
    border-left: 5px solid #005577;
    border-radius: 10px;
    color: #002C3D;
    text-align: left;
}

.mission-statement h2 {
    color: #005577;
    font-size: 1.75em;
    margin-bottom: 15px;
    font-style: italic;
}

.mission-statement p {
    font-size: 1.1em;
    line-height: 1.6;
    color: #002C3D;
}

@media (max-width: 768px) {
    .mission-statement {
        padding: 15px;
    }

    .mission-statement h2 {
        font-size: 1.5em;
    }

    .mission-statement p {
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .mission-statement {
        padding: 10px;
    }

    .mission-statement h2 {
        font-size: 1.25em;
    }

    .mission-statement p {
        font-size: 0.9em;
    }
}
