.landing-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.landing-page-header {
    padding: 2vh 2vw;
    text-align: center;
    background-color: #282c34;
    color: white;
}

main {
    flex-grow: 1;
    padding: 2vh 2vw;
}

footer {
    padding: 1vh 2vw;
    text-align: center;
    background-color: #282c34;
    color: white;
}
